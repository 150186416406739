export default [
  {
    title: 'Dashboards',
    icon: 'HomeIcon',
    tag: '2',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Shaps',
        route: 'dashboard-ecommerce',
        resource: 'dashboard',
        action: 'read',
      },
      // {
      //   title: 'Analytics',
      //   route: 'dashboard-analytics',
      //   resource: 'dashboard',
      //   action: 'read',
      // },
    ],
  },
]
