export default [
  {
    header: 'Accounting',
    resource: 'AccouningHeader',
    action: 'read',
  },
  {
    title: 'Restrictions',
    route: 'accounting-restrictions',
    icon: 'TrelloIcon',
    resource: 'Restriction',
    action: 'read',
  },
  {
    title: 'Accounts Tree',
    route: 'accounting-accountsTree',
    icon: 'GitMergeIcon',
    resource: 'AccountTree',
    action: 'read',
  },
  {
    title: 'Cost Centers',
    route: 'accounting-costCenters',
    icon: 'ServerIcon',
    resource: 'CostCenter',
    action: 'read',
  },
  {
    title: 'Financial Years',
    route: 'accounting-financialYears',
    icon: 'CalendarIcon',
    resource: 'FinancialYear',
    action: 'read',
  },
  {
    title: 'Report',
    icon: 'AlignJustifyIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Accounting Statement',
        route: 'accounting-statements',
        resource: 'AccountingStatement',
        action: 'read',
      },
      {
        title: 'Accounting Movement',
        route: 'accounting-movements',
        resource: 'AccountingMovement',
        action: 'read',
      },
      {
        title: 'Trial Balance',
        route: 'accounting-trialBalance',
        resource: 'TrialBalance',
        action: 'read',
      },
      {
        title: 'Balance Sheet',
        route: 'accounting-balanceSheet',
        resource: 'BalanceSheet',
        action: 'read',
      },
    ],
  },
]
