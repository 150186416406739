export default [
  {
    header: 'Sales',
    resource: 'SalesHeader',
    action: 'read',
  },
  {
    title: 'Sales Request',
    route: 'sale-request',
    icon: 'TwitchIcon',
    resource: 'SalesRequest',
    action: 'read',
  },
  {
    title: 'Sales Price Offer',
    route: 'sale-offer',
    icon: 'AirplayIcon',
    resource: 'SalesPriceOffer',
    action: 'read',
  },
  {
    title: 'Sales Order',
    route: 'sale-order',
    icon: 'ShoppingBagIcon',
    resource: 'SalesOrder',
    action: 'read',
  },
  {
    title: 'Sales Invoice',
    route: 'sale-invoice',
    icon: 'ShoppingCartIcon',
    resource: 'SalesInvoice',
    action: 'read',
  },
  {
    title: 'Sales Return',
    route: 'sale-return',
    icon: 'RepeatIcon',
    resource: 'SalesReturn',
    action: 'read',
  },
  {
    // children: [
    //   {
    //     title: 'Sales',
    //     icon: 'AlignJustifyIcon',
    //     tagVariant: 'light-warning',
    //     resource: 'SalesReturn',
    //     action: 'read',
    //     children: [
    //       {
    //         title: 'By Date',
    //         route: 'sales-report-invoiceByDate',
    //         resource: 'SalesReturn',
    //         action: 'read',
    //       },
    //     ],
    //   },
    //   {
    title: 'Sales Report',
    icon: 'AlignJustifyIcon',
    tagVariant: 'light-warning',
    resource: 'SalesHeader',
    action: 'read',
    children: [
      {
        title: 'Sales Invoice Report',
        route: 'sale-report-invoice',
        resource: 'SalesInvoice',
        action: 'read',
      },
      {
        title: 'Sales Order Report',
        route: 'sale-report-order',
        resource: 'SalesOrder',
        action: 'read',
      },
      {
        title: 'Sales Price Offer Report',
        route: 'sale-report-offer',
        resource: 'SalesPriceOffer',
        action: 'read',
      },
      {
        title: 'Sales Request Report',
        route: 'sale-report-request',
        resource: 'SalesRequest',
        action: 'read',
      },
      {
        title: 'Sales Return Report',
        route: 'sale-report-return',
        resource: 'SalesReturn',
        action: 'read',
      },
      // children: [
      //   {
      //     title: 'By Date',
      //     route: 'sales-report-invoiceByDate',
      //     resource: 'SalesInvoice',
      //     action: 'read',
      //   },
      //   {
      //     title: 'By Due Date',
      //     route: 'sales-report-invoiceByDueDate',
      //     resource: 'SalesInvoice',
      //     action: 'read',
      //   },
      //   {
      //     title: 'By Warehouse',
      //     route: 'sales-report-invoiceByWarehouse',
      //     resource: 'SalesInvoice',
      //     action: 'read',
      //   },
      //   {
      //     title: 'By Branch',
      //     route: 'sales-report-invoiceByBranch',
      //     resource: 'SalesInvoice',
      //     action: 'read',
      //   },
      //   {
      //     title: 'By Financial Year',
      //     route: 'sales-report-invoiceByFinancialYear',
      //     resource: 'SalesInvoice',
      //     action: 'read',
      //   },
      //   {
      //     title: 'By Client',
      //     route: 'sales-report-invoiceByClient',
      //     resource: 'SalesInvoice',
      //     action: 'read',
      //   },
      //   {
      //     title: 'By Based Document',
      //     route: 'sales-report-invoiceByBasedDocument',
      //     resource: 'SalesInvoice',
      //     action: 'read',
      //   },
      //   {
      //     title: 'By Status',
      //     route: 'sales-report-invoiceByStatus',
      //     resource: 'SalesInvoice',
      //     action: 'read',
      //   },
      //   {
      //     title: 'By Item',
      //     route: 'sales-report-invoiceByItem',
      //     resource: 'SalesInvoice',
      //     action: 'read',
      //   },
      //   {
      //     title: 'By Employee',
      //     route: 'sales-report-invoiceByUser',
      //     resource: 'SalesInvoice',
      //     action: 'read',
      //   },
      // ],
    ],
  },
]
