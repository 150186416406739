export default [
  {
    header: 'CRM',
    resource: 'CRMHeader',
    action: 'read',
  },
  {
    title: 'Suppliers',
    route: 'crm-suppliers',
    icon: 'UserIcon',
    resource: 'Supplier',
    action: 'read',
  },
  {
    title: 'Clients',
    route: 'crm-clients',
    icon: 'UsersIcon',
    resource: 'Client',
    action: 'read',
  },
  {
    title: 'Companies',
    route: 'crm-companies',
    icon: 'HomeIcon',
    resource: 'Company',
    action: 'read',
  },
  {
    title: 'Types',
    route: 'crm-types',
    icon: 'BookmarkIcon',
    resource: 'TypeSupplier',
    action: 'read',
  },
]
