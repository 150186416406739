export default [
  {
    header: 'Purchases',
    resource: 'PurchasesHeader',
    action: 'read',
  },
  {
    title: 'Purchases Request',
    route: 'purchase-request',
    icon: 'TwitchIcon',
    resource: 'PurchasesRequest',
    action: 'read',
  },
  {
    title: 'Purchases Price Offer',
    route: 'purchase-offer',
    icon: 'AirplayIcon',
    resource: 'PurchasesPriceOffer',
    action: 'read',
  },
  {
    title: 'Purchases Order',
    route: 'purchase-order',
    icon: 'FeatherIcon',
    resource: 'PurchasesOrder',
    action: 'read',
  },
  {
    title: 'Purchases Invoice',
    route: 'purchase-invoice',
    icon: 'FileTextIcon',
    resource: 'PurchasesInvoice',
    action: 'read',
  },
  {
    title: 'Purchases Return',
    route: 'purchase-return',
    icon: 'UploadIcon',
    resource: 'PurchasesReturn',
    action: 'read',
  },
  {
    title: 'Purchases Report',
    icon: 'AlignJustifyIcon',
    tagVariant: 'light-warning',
    resource: 'PurchasesHeader',
    action: 'read',
    children: [
      {
        title: 'Purchases Invoice Report',
        route: 'purchase-report-invoice',
        resource: 'PurchasesInvoice',
        action: 'read',
      },
      {
        title: 'Purchases Order Report',
        route: 'purchase-report-order',
        resource: 'PurchasesOrder',
        action: 'read',
      },
      {
        title: 'Purchases Price Offer Report',
        route: 'purchase-report-offer',
        resource: 'PurchasesPriceOffer',
        action: 'read',
      },
      {
        title: 'Purchases Request Report',
        route: 'purchase-report-request',
        resource: 'PurchasesRequest',
        action: 'read',
      },
      {
        title: 'Purchases Return Report',
        route: 'purchase-report-return',
        resource: 'PurchasesReturn',
        action: 'read',
      },
    ],
  },
]
