export default [
  {
    header: 'Warehouse',
    resource: 'WarehouseHeader',
    action: 'read',
  },
  {
    title: 'Deposit Warehouse',
    route: 'warehouse-deposit',
    icon: 'SaveIcon',
    resource: 'DepositWarehouse',
    action: 'read',
  },
  {
    title: 'Withdraw Warehouse',
    route: 'warehouse-withdraw',
    icon: 'GiftIcon',
    resource: 'WithdrawWarehouse',
    action: 'read',
  },
  {
    title: 'Transfer Warehouses',
    route: 'warehouse-transferWarehouses',
    icon: 'TruckIcon',
    resource: 'TransferWarehouse',
    action: 'read',
  },
  {
    title: 'Destroy Warehouse',
    route: 'warehouse-destroy',
    icon: 'ScissorsIcon',
    resource: 'DestroyWarehouse',
    action: 'read',
  },
  {
    title: 'Items',
    route: 'warehouse-items',
    icon: 'ArchiveIcon',
    resource: 'Item',
    action: 'read',
  },
  {
    title: 'Items Groups',
    route: 'warehouse-itemGroups',
    icon: 'LayersIcon',
    resource: 'ItemGroup',
    action: 'read',
  },
  {
    title: 'Item Types',
    route: 'warehouse-itemTypes',
    icon: 'AwardIcon',
    resource: 'ItemType',
    action: 'read',
  },
  {
    title: 'Item Structures',
    route: 'warehouse-itemStructures',
    icon: 'Share2Icon',
    resource: 'ItemStructure',
    action: 'read',
  },
  {
    title: 'Brands',
    route: 'warehouse-brands',
    icon: 'FramerIcon',
    resource: 'Brand',
    action: 'read',
  },
  {
    title: 'Logicteds',
    route: 'warehouse-logicteds',
    icon: 'SendIcon',
    resource: 'Logicted',
    action: 'read',
  },
  {
    title: 'Measurement Units',
    route: 'warehouse-measurementUnits',
    icon: 'BoxIcon',
    resource: 'MeasurementUnit',
    action: 'read',
  },
  {
    title: 'Warehouses',
    route: 'warehouse-warehouses',
    icon: 'DatabaseIcon',
    resource: 'Warehouse',
    action: 'read',
  },
  {
    title: 'Warehouse Parts',
    route: 'warehouse-warehouseParts',
    icon: 'InboxIcon',
    resource: 'WarehousePart',
    action: 'read',
  },

  {
    title: 'Warehouse Report',
    icon: 'AlignJustifyIcon',
    tagVariant: 'light-warning',
    resource: 'WarehouseHeader',
    action: 'read',
    children: [
      {
        title: 'Deposit Warehouse Report',
        route: 'warehouse-report-deposit',
        resource: 'DepositWarehouse',
        action: 'read',
      },
      {
        title: 'Withdraw Warehouse Report',
        route: 'warehouse-report-withdraw',
        resource: 'WithdrawWarehouse',
        action: 'read',
      },
      {
        title: 'Transfer Warehouse Report',
        route: 'warehouse-report-transferWarehouses',
        resource: 'TransferWarehouse',
        action: 'read',
      },
      {
        title: 'Destroy Warehouse Report',
        route: 'warehouse-report-destroy',
        resource: 'DestroyWarehouse',
        action: 'read',
      },
      {
        title: 'Status Warehouse Report',
        route: 'warehouse-report-status',
        resource: 'WarehouseHeader',
        action: 'read',
      },
    ],
  },
]
