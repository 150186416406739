export default [
  {
    header: 'Administration',
    resource: 'SystemAdminHeader',
    action: 'read',
  },
  {
    title: 'Employees',
    route: 'administration-employees',
    icon: 'UserIcon',
    resource: 'Employee',
    action: 'read',
  },
  {
    title: 'Groups',
    route: 'administration-groups',
    icon: 'GridIcon',
    resource: 'Group',
    action: 'read',
  },
  {
    title: 'Documents',
    route: 'administration-documents',
    icon: 'PaperclipIcon',
    resource: 'Document',
    action: 'read',
  },
  {
    title: 'Accounting Operations',
    route: 'administration-accountingOperations',
    icon: 'SettingsIcon',
    resource: 'AccountingOperation',
    action: 'read',
  },
  {
    title: 'Payments',
    route: 'administration-payments',
    icon: 'DollarSignIcon',
    resource: 'Payment',
    action: 'read',
  },
  {
    title: 'Statuses',
    route: 'administration-statuses',
    icon: 'StarIcon',
    resource: 'Status',
    action: 'read',
  },
  {
    title: 'Organizations',
    route: 'administration-organizations',
    icon: 'CircleIcon',
    resource: 'Organization',
    action: 'read',
  },
  {
    title: 'Branches',
    route: 'administration-branches',
    icon: 'GitBranchIcon',
    resource: 'Branch',
    action: 'read',
  },
  {
    title: 'Banks',
    route: 'administration-banks',
    icon: 'HomeIcon',
    resource: 'Bank',
    action: 'read',
  },
  {
    title: 'Bank Branches',
    route: 'administration-bankBranches',
    icon: 'CreditCardIcon',
    resource: 'BankBranch',
    action: 'read',
  },
  {
    title: 'Calendars',
    route: 'administration-calendars',
    icon: 'CalendarIcon',
    resource: 'Calendar',
    action: 'read',
  },
  {
    title: 'Nationalities',
    route: 'administration-nationalities',
    icon: 'ChromeIcon',
    resource: 'Nationality',
    action: 'read',
  },
  {
    title: 'Countries',
    route: 'administration-countries',
    icon: 'FlagIcon',
    resource: 'Country',
    action: 'read',
  },
  {
    title: 'Cities',
    route: 'administration-cities',
    icon: 'MapIcon',
    resource: 'City',
    action: 'read',
  },
  {
    title: 'Areas',
    route: 'administration-areas',
    icon: 'MapPinIcon',
    resource: 'Area',
    action: 'read',
  },
  {
    title: 'Contract Types',
    route: 'administration-contractTypes',
    icon: 'ClipboardIcon',
    resource: 'ContractType',
    action: 'read',
  },
  {
    title: 'Gender Degreer',
    route: 'administration-genderDegreers',
    icon: 'UsersIcon',
    resource: 'GenderDegreer',
    action: 'read',
  },
  {
    title: 'Genders',
    route: 'administration-genders',
    icon: 'FeatherIcon',
    resource: 'Gender',
    action: 'read',
  },
  {
    title: 'Languages',
    route: 'administration-languages',
    icon: 'GlobeIcon',
    resource: 'Language',
    action: 'read',
  },
  {
    title: 'Social Status',
    route: 'administration-socialStatuses',
    icon: 'GitMergeIcon',
    resource: 'SocialStatus',
    action: 'read',
  },
]
