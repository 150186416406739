/* eslint-disable */
// prettier-ignore
export default {
  pages: {
    key: 'title',
    data: [
      { title: 'Clients', route: { name: 'crm-addClient' }, icon: 'UsersIcon', isBookmarked: true },
      { title: 'Restrictions', route: { name: 'accounting-addRestriction' }, icon: 'TrelloIcon', isBookmarked: true },
      { title: 'Accounts Tree', route: { name: 'accounting-addAccountTree' }, icon: 'GitMergeIcon', isBookmarked: false },
      { title: 'Financial Years', route: { name: 'accounting-addFinancialYear' }, icon: 'CalendarIcon', isBookmarked: false },
      { title: 'Cost Centers', route: { name: 'accounting-addCostCenter' }, icon: 'ServerIcon', isBookmarked: false },
      { title: 'Sales Invoice', route: { name: 'sale-addSalesInvoice' }, icon: 'ShoppingCartIcon', isBookmarked: true },
      { title: 'Sales Order', route: { name: 'sale-addSalesOrder' }, icon: 'ShoppingBagIcon', isBookmarked: false },
      { title: 'Sales Price Offer', route: { name: 'sale-addPriceOffer' }, icon: 'AirplayIcon', isBookmarked: false },
      { title: 'Sales Request', route: { name: 'sale-addSalesRequest' }, icon: 'TwitchIcon', isBookmarked: false },
      { title: 'Sales Return', route: { name: 'sale-addSalesReturn' }, icon: 'RepeatIcon', isBookmarked: false },
      { title: 'Purchases Invoice', route: { name: 'purchase-addPurchasesInvoice' }, icon: 'FileTextIcon', isBookmarked: true },
      { title: 'Purchases Order', route: { name: 'purchase-addPurchasesOrder' }, icon: 'FeatherIcon', isBookmarked: false },
      { title: 'Purchases Price Offer', route: { name: 'purchase-addPriceOffer' }, icon: 'AirplayIcon', isBookmarked: false },
      { title: 'Purchases Request', route: { name: 'purchase-addPurchasesRequest' }, icon: 'TwitchIcon', isBookmarked: false },
      { title: 'Purchases Return', route: { name: 'purchase-addPurchasesReturn' }, icon: 'UploadIcon', isBookmarked: false },
      { title: 'Suppliers', route: { name: 'crm-addSupplier' }, icon: 'UserIcon', isBookmarked: false },
      { title: 'Companies', route: { name: 'crm-addCompany' }, icon: 'HomeIcon', isBookmarked: false },
      { title: 'Types', route: { name: 'crm-addType' }, icon: 'BookmarkIcon', isBookmarked: false },
      { title: 'Areas', route: { name: 'administration-addArea' }, icon: 'MapPinIcon', isBookmarked: false },
      { title: 'Banks', route: { name: 'administration-addBank' }, icon: 'HomeIcon', isBookmarked: false },
      { title: 'Bank Branches', route: { name: 'administration-addBranch' }, icon: 'CreditCardIcon', isBookmarked: false },
      { title: 'Branches', route: { name: 'administration-addBankBranch' }, icon: 'GitBranchIcon', isBookmarked: false },
      { title: 'Calendars', route: { name: 'administration-addCalendar' }, icon: 'CalendarIcon', isBookmarked: false },
      { title: 'Cities', route: { name: 'administration-addCity' }, icon: 'MapIcon', isBookmarked: false },
      { title: 'Contract Types', route: { name: 'administration-addContractType' }, icon: 'ClipboardIcon', isBookmarked: false },
      { title: 'Countries', route: { name: 'administration-addCountry' }, icon: 'FlagIcon', isBookmarked: false },
      { title: 'Documents', route: { name: 'administration-addDocument' }, icon: 'PaperclipIcon', isBookmarked: true },
      { title: 'Employees', route: { name: 'administration-addEmployee' }, icon: 'UserIcon', isBookmarked: true },
      { title: 'Gender Degreer', route: { name: 'administration-addGenderDegreer' }, icon: 'UsersIcon', isBookmarked: false },
      { title: 'Genders', route: { name: 'administration-addGender' }, icon: 'FeatherIcon', isBookmarked: false },
      { title: 'Groups', route: { name: 'administration-addGroup' }, icon: 'GridIcon', isBookmarked: false },
      { title: 'Languages', route: { name: 'administration-addLanguage' }, icon: 'GlobeIcon', isBookmarked: false },
      { title: 'Nationalities', route: { name: 'administration-addNationality' }, icon: 'ChromeIcon', isBookmarked: false },
      { title: 'Organizations', route: { name: 'administration-addOrganization' }, icon: 'CircleIcon', isBookmarked: false },
      { title: 'Social Status', route: { name: 'administration-addSocialStatus' }, icon: 'GitMergeIcon', isBookmarked: false },
      { title: 'Payments', route: { name: 'administration-addPayment' }, icon: 'DollarSignIcon', isBookmarked: false },
      { title: 'Accounting Operations', route: { name: 'administration-addAccountingOperation' }, icon: 'SettingsIcon', isBookmarked: true },
      { title: 'Statuses', route: { name: 'administration-addStatus' }, icon: 'StarIcon', isBookmarked: false },
      { title: 'Deposit Warehouse', route: { name: 'warehouse-addDeposit' }, icon: 'SaveIcon', isBookmarked: true },
      { title: 'Withdraw Warehouse', route: { name: 'warehouse-addWithdraw' }, icon: 'GiftIcon', isBookmarked: true },
      { title: 'Transfer Warehouses', route: { name: 'warehouse-addTransferWarehouse' }, icon: 'TruckIcon', isBookmarked: false },
      { title: 'Destroy Warehouse', route: { name: 'warehouse-addDestroy' }, icon: 'ScissorsIcon', isBookmarked: false },
      { title: 'Items', route: { name: 'warehouse-addItem' }, icon: 'ArchiveIcon', isBookmarked: false },
      { title: 'Items Groups', route: { name: 'warehouse-addItemGroup' }, icon: 'LayersIcon', isBookmarked: false },
      { title: 'Item Types', route: { name: 'warehouse-addItemType' }, icon: 'AwardIcon', isBookmarked: false },
      { title: 'Item Structures', route: { name: 'warehouse-addItemStructure' }, icon: 'Share2Icon', isBookmarked: false },
      { title: 'Brands', route: { name: 'warehouse-addBrand' }, icon: 'FramerIcon', isBookmarked: false },
      { title: 'Logicteds', route: { name: 'warehouse-addLogicted' }, icon: 'SendIcon', isBookmarked: false },
      { title: 'Measurement Units', route: { name: 'warehouse-addMeasurementUnit' }, icon: 'BoxIcon', isBookmarked: false },
      { title: 'Warehouses', route: { name: 'warehouse-addWarehouse' }, icon: 'DatabaseIcon', isBookmarked: false },
      { title: 'Warehouse Parts', route: { name: 'warehouse-addWarehousePart' }, icon: 'InboxIcon', isBookmarked: false },
    ],
  },
  // files: {
  //   key: 'file_name',
  //   data: [
  //     {
  //       file_name: "Joe's CV",
  //       from: 'Stacy Watson',
  //       icon: require('@/assets/images/icons/doc.png'),
  //       size: '1.7 mb',
  //     },
  //     {
  //       file_name: 'Passport Image',
  //       from: 'Ben Sinitiere',
  //       icon: require('@/assets/images/icons/jpg.png'),
  //       size: ' 52 kb',
  //     },
  //     {
  //       file_name: 'Questions',
  //       from: 'Charleen Patti',
  //       icon: require('@/assets/images/icons/doc.png'),
  //       size: '1.5 gb',
  //     },
  //     {
  //       file_name: 'Parenting Guide',
  //       from: 'Doyle Blatteau',
  //       icon: require('@/assets/images/icons/doc.png'),
  //       size: '2.3 mb',
  //     },
  //     {
  //       file_name: 'Class Notes',
  //       from: 'Gwen Greenlow',
  //       icon: require('@/assets/images/icons/doc.png'),
  //       size: ' 30 kb',
  //     },
  //     {
  //       file_name: 'Class Attendance',
  //       from: 'Tom Alred',
  //       icon: require('@/assets/images/icons/xls.png'),
  //       size: '52 mb',
  //     },
  //     {
  //       file_name: 'Company Salary',
  //       from: 'Nellie Dezan',
  //       icon: require('@/assets/images/icons/xls.png'),
  //       size: '29 mb',
  //     },
  //     {
  //       file_name: 'Company Logo',
  //       from: 'Steve Sheldon',
  //       icon: require('@/assets/images/icons/jpg.png'),
  //       size: '1.3 mb',
  //     },
  //     {
  //       file_name: 'Crime Rates',
  //       from: 'Sherlock Holmes',
  //       icon: require('@/assets/images/icons/xls.png'),
  //       size: '37 kb',
  //     },
  //     {
  //       file_name: 'Ulysses',
  //       from: 'Theresia Wrenne',
  //       icon: require('@/assets/images/icons/pdf.png'),
  //       size: '7.2 mb',
  //     },
  //     {
  //       file_name: 'War and Peace',
  //       from: 'Goldie Highnote',
  //       icon: require('@/assets/images/icons/pdf.png'),
  //       size: '10.5 mb',
  //     },
  //     {
  //       file_name: 'Vedas',
  //       from: 'Ajay Patel',
  //       icon: require('@/assets/images/icons/pdf.png'),
  //       size: '8.3 mb',
  //     },
  //     {
  //       file_name: 'The Trial',
  //       from: 'Sirena Linkert',
  //       icon: require('@/assets/images/icons/pdf.png'),
  //       size: '1.5 mb',
  //     },
  //   ],
  // },
  // contacts: {
  //   key: 'name',
  //   data: [
  //     {
  //       img: require('@/assets/images/portrait/small/avatar-s-4.jpg'),
  //       name: 'Rena Brant',
  //       email: 'nephrod@preany.co.uk',
  //       time: '21/05/2019',
  //     },
  //     {
  //       img: require('@/assets/images/portrait/small/avatar-s-2.jpg'),
  //       name: 'Mariano Packard',
  //       email: 'seek@sparaxis.org',
  //       time: '14/01/2018',
  //     },
  //     {
  //       img: require('@/assets/images/portrait/small/avatar-s-24.jpg'),
  //       name: 'Risa Montufar',
  //       email: 'vagary@unblist.org',
  //       time: '10/08/2019',
  //     },
  //     {
  //       img: require('@/assets/images/portrait/small/avatar-s-15.jpg'),
  //       name: 'Maragaret Cimo',
  //       email: 'designed@insanely.net',
  //       time: '01/12/2019',
  //     },
  //     {
  //       img: require('@/assets/images/portrait/small/avatar-s-7.jpg'),
  //       name: 'Jona Prattis',
  //       email: 'unwieldable@unblist.org',
  //       time: '21/05/2019',
  //     },
  //     {
  //       img: require('@/assets/images/portrait/small/avatar-s-5.jpg'),
  //       name: 'Edmond Chicon',
  //       email: 'museist@anaphyte.co.uk',
  //       time: '15/11/2019',
  //     },
  //     {
  //       img: require('@/assets/images/portrait/small/avatar-s-25.jpg'),
  //       name: 'Abbey Darden',
  //       email: 'astema@defectively.co.uk',
  //       time: '07/05/2019',
  //     },
  //     {
  //       img: require('@/assets/images/portrait/small/avatar-s-10.jpg'),
  //       name: 'Seema Moallankamp',
  //       email: 'fernando@storkish.co.uk',
  //       time: '13/08/2017',
  //     },
  //     {
  //       img: require('@/assets/images/portrait/small/avatar-s-2.jpg'),
  //       name: 'Charleen Warmington',
  //       email: 'furphy@cannibal.net',
  //       time: '11/08/1891',
  //     },
  //     {
  //       img: require('@/assets/images/portrait/small/avatar-s-25.jpg'),
  //       name: 'Geri Linch',
  //       email: 'insignia@markab.org',
  //       time: '18/01/2015',
  //     },
  //     {
  //       img: require('@/assets/images/portrait/small/avatar-s-23.jpg'),
  //       name: 'Shellie Muster',
  //       email: 'maxillary@equalize.co.uk',
  //       time: '26/07/2019',
  //     },
  //     {
  //       img: require('@/assets/images/portrait/small/avatar-s-20.jpg'),
  //       name: 'Jesenia Vanbramer',
  //       email: 'hypotony@phonetist.net',
  //       time: '12/09/2017',
  //     },
  //     {
  //       img: require('@/assets/images/portrait/small/avatar-s-23.jpg'),
  //       name: 'Mardell Channey',
  //       email: 'peseta@myrica.com',
  //       time: '11/11/2019',
  //     },
  //   ],
  // },
}
/* eslint-enable */
